.contact_contents {
  width: 100%;
  padding: 0 4rem;
}
@media (max-width: 640px) {
  .contact_contents {
    padding: 0 2rem;
  }
}
.hero_top_contact {
  background-image: url(img/hero/08.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;;
  height: 320px;
}
.hero_top_contact h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  letter-spacing: 0.2rem;
  color: #fff;
  text-align: center;
  line-height: 3.5rem;
  font-family: 'Caveat Brush', cursive;
  span {
    font-size: 1.2rem;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  }
}
@media (max-width: 640px) {
  .hero_top_contact {
      height: 10rem;
      h1 span {
        font-size: 1rem;
      }
    }
}

.contact_contents p {
  letter-spacing: 0.05rem;
  line-height: 1.3rem;
  text-align: center;
}
.contact_text {
  margin: 2rem 0;
}
table {
    border-collapse: collapse;
    max-width: 80%;
    margin: 0 auto;
}

form td {
    border: 1px solid $color-main;
    padding: 1rem;
    line-height: 1.4rem;
}

form th {
    border: 1px solid $color-main;
    padding: 1rem;
    width: 25%;
    vertical-align: middle;
    line-height: 1.3rem;
}

input[type="text"],input[type="email"], textarea {
    border: 1px solid #fff;
    background: #eee;
    line-height: 1.3rem;
    width: 100%;
}

input[type="submit"] {
    border: 1px solid $color-main;
    color: $color-main;
    background: #fff;
    border-radius: 100px;
    width: 100px;
    margin: 2rem 0;
    padding: 0.5rem;
    &:hover {
      color: #fff;
      background: $color-main;
    }
}
@media (max-width: 960px) {
  table {
      max-width: 100%;
  }
}
@media (max-width: 640px) {
  form th {
      width: 35%;
    }
}
