.concept_contents {
  display: Flex;
  justify-content: space-between;
}
.concept_wrapper {
  padding-bottom: 2rem;
  margin-bottom: 4rem;
}
.hero_top_concept {
  background-image: url(img/hero/01.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;;
  height: 320px;
}
.hero_top_concept h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  letter-spacing: 0.2rem;
  color: #fff;
  text-align: center;
  line-height: 3.5rem;
  font-family: 'Caveat Brush', cursive;
  span {
    font-size: 1.2rem;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  }
}
@media (max-width: 640px){
  .hero_top_concept{
    height: 10rem;
  }
}
.concept_left {
  width: 70%;
  padding: 2rem 4rem;
  p {
    line-height: 1.4rem;
    letter-spacing: 0.05rem;
    margin-bottom: 1rem;
    span {
      display: block;
      font-weight: bold;
      font-size: 1.2rem;
      line-height: 2rem;
      margin-bottom: 1rem;
      color: $color-main;
    }
    strong {
      background: linear-gradient(transparent 50%, #ffff66 30%);
    }
  }
}
.concept_right {
  padding:2rem 4rem 2rem 0;
  width: 30%;
  img {
    width:  100%;
    box-shadow: 1px 1px 5px #ccc;
  }
  p {
    padding-top: 1rem;
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
}
@media (max-width: 768px) {
  .concept_contents {
    display: block;
  }
  .concept_left {
    width: 100%;
    padding: 1rem 3rem;
  }
  .concept_right {
    width: 100%;
    padding: 1rem 3rem;
  }
}
@media (max-width: 640px) {
  .concept_left {
    padding: 1rem 2rem;
  }
  .concept_right {
    padding: 1rem 2rem;
  }
}
