.hero_top_comunity {
  background-image: url(img/hero/05.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;;
  height: 320px;
}
.hero_top_comunity h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  letter-spacing: 0.2rem;
  color: #fff;
  text-align: center;
  line-height: 3.5rem;
  font-family: 'Caveat Brush', cursive;
  span {
    font-size: 1.2rem;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  }
}
@media (max-width: 640px) {
  .hero_top_comunity{
    height: 10rem;
  }
}
.comunity_btn ul {display: flex;justify-content: space-between;margin-top: 2rem;}

.comunity_btn li a {
    display: block;
    text-decoration: none;
    &:hover {
      opacity: 0.6;
    }
}
.comunity_btn li a img {
    border: 1px solid $color-main;
    width: 100%;
}
.comunity_btn li {
    width: 45%;
}
@media (max-width: 640px) {
  .comunity_btn ul {
    display: block;
    li {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}
/*   pasha展ページ   */
.slider-area1 {
  max-width: 400px;
  margin: 0 auto;
}
.slider-area2 {
  width: 600px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  div.member_contents {
    width: 100%;
  }
}
@media (max-width: 640px) {
  .slider-area1 {
    max-width: 100%;
  }
  .slider-area2 {
    width: 100%;
  }
}
.koten_img {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  li {
    width: 25%;
    text-align: center;
    margin-bottom: 2rem;
  }
  img {
    width: 150px;
    box-shadow: 1px 1px 3px #999;
  }
}
@media (max-width: 1080px) {
  .koten_img li {
    img {
      width: 100px;
    }
  }
}
@media (max-width: 640px) {
  .koten_img li {
    width: 50%;
    img {
      width: 90%;
    }
  }

}
.modal-content {
	width: 500px ;
	margin: 0 ;
	padding: 10px;
	background: #fff ;
	position: fixed ;
	display: none ;
	z-index: 9999999 ;
  text-align: center;
  p a {
    color: #fff;
    text-decoration: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 4rem;
    vertical-align: middle;
    color: $color-main;
    &:hover {
      opacity: 0.5;
      color: $color-main;
    }
  }
}

.modal-size img {
  width: 100%;
}
@media (max-width: 640px) {
  .modal-content {
    width: 80%;
  }
  .modal-size img {
    width: 100%;
  }
}
#modal-overlay {
	z-index: 99999 ;
	display: none ;
	position: fixed ;
	top: 0 ;
	left: 0 ;
	width: 100% ;
	height: 120% ;
	background-color: rgba( 0,0,0, 0.75 ) ;
}

.button-link {
	color: #00f ;
	text-decoration: underline ;
}

.button-link:hover {
	cursor: pointer ;
	color: #f00 ;
}
.slider-area2 p {
  text-align: center;
  color: $color-main;
  margin-top: 2rem;
}
/*-------------pasha塾---------------*/
.pasha_contents h3 {
    padding: 0.5rem;
    background: $color-main;
    border-radius: 100px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;

}
.pasha_contents h4 {
    color: $color-main;
    margin-bottom: 1rem;
    text-align: center;
    line-height: 1.3rem;
}
.pasha_contents ul {
    margin-bottom: 1rem;
}
.pasha_contents li {
    line-height: 1.5rem;
}
.pasha_contents_img img,.pasha_contents_img2 img {
    width: 100%;
}
.pasha_contents_img {
    width: 45%;
    margin-right: 0;
    margin-left: auto;
    margin-bottom: -240px;
}
.pasha_contents_img2 {
    width: 35%;
    margin-top: 80px;
    margin-bottom: 2rem;
}
.pasha_contents1 {
  width: 65%;
  background: #fff;
  box-shadow: 0 0 5px #999;
  position: relative;
  padding: 2rem;
}
.pasha_contents2 {
  width: 75%;
  margin-right: 0;
  margin-left: auto;
  background: #fff;
  box-shadow: 0 0 5px #999;
  position: relative;
  padding: 2rem;
  margin-top: -350px;
}
@media (max-width: 1200px) {
  .pasha_contents_img {
      width: 100%;
      margin-bottom: 0px;
  }
  .pasha_contents_img2 {
      width: 100%;
      margin-top: 0px;
  }
  .pasha_contents1 {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 3rem;
    margin-top: -50px;
  }
  .pasha_contents2 {
    width: 90%;
    margin: 0 auto;
    margin-top: -50px;
  }
}
@media (max-width: 640px) {
  .pasha_contents1 {
    padding: 1rem;
  }
  .pasha_contents2 {
    padding: 1rem;
  }
}
