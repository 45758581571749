/*-----------------------------
全体構造
-----------------------------*/
html {
  height:100%;
}
body {
  height: 100%;
  background: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  font-size: 14px;
  color: #212121;
}
* {
  box-sizing: border-box;
}

/*-----------------------------
カラー
-----------------------------*/
$color-main: #11a4bf;
$color-gray: #efefef;
$color-dark: #212121;

/*-----------------------------
フォント
-----------------------------*/
.fsz14 {
  font-size: 14px;
}
.fsz18 {
  font-size: 18px;
}
.ff-hand {
  font-family: 'Caveat Brush', cursive;
}
.ff-hikki {
  font-family: 'Sacramento', cursive;
}
/*-----------------------------
デバイス対応
-----------------------------*/
.sp {
  display: none;
}
@media (max-width: 768px) {
  .sp {
    display: block;
  }
  .pc {
    display: none;
  }
}
/*-----------------------------
パーツ
-----------------------------*/
.main {
  background: $color-gray;
  padding: 5rem 0;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
}
@media (max-width: 1200px) {
  .container {
    width: 90%;
    margin: 0 auto;
  }
}
.hero_top {
  width: 100%;
  height: 320px;
}
@media (max-width: 768px) {
  .hero_top {
    width: 100%;
    height: 30%;
  }
}
