.footer {
    padding: 2rem;
    text-align: center;
}

.footer img {width: 83px;margin-bottom: 2rem;}

.footer_nav_list {
    display: flex;
    justify-content: space-around;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 2rem;
}

.footer_nav_list li a {
    padding: 1rem;
    display: block;
    text-decoration: none;
    color: #333;
}

.footer_nav_list li a:after {
    content: "|";
    padding-left: 2rem;
}

.footer_nav_list li:first-child a:before {
    content: "|";
    padding-right: 2rem;
}
@media (max-width: 960px){
  .footer_nav_list li a:after {
    content: "";
    padding-left: 0;
  }
  .footer_nav_list li:first-child a:before {
      content: "";
      padding-right: 0;
  }
}
@media (max-width: 640px){
  .footer_nav_list {
      display: block;
      line-height: 1.2rem;
  }
  .footer_nav_list li a {
      padding: 0.4rem;
    }
}
