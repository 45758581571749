.member_contents {
  width: 100%;
  padding: 0 4rem;
  h2 {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 1rem 0 2rem 0;
    color: $color-main;
  }
  p {
    line-height: 1.3rem;
    margin-bottom: 1rem;
    strong {
      background: linear-gradient(transparent 40%, #ffff66 40%);
    }
  }
}
@media (max-width: 640px) {
  .member_contents {
    padding: 0 2rem;
  }
}
.hero_top_about {
  background-image: url(img/hero/10.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;;
  height: 320px;
}
.hero_top_about h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  letter-spacing: 0.2rem;
  color: #fff;
  text-align: center;
  line-height: 3.5rem;
  font-family: 'Caveat Brush', cursive;
  span {
    font-size: 1.2rem;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  }
}
.about h3 {
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 2rem;
    line-height: 1.8rem;
    font-family: Georgia,游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
    letter-spacing: 0.1rem;
}
.about dl {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 4rem;
}
.about dt {
  float: left;
  clear: left;
  width: 15%;
  line-height: 1.8rem;
  vertical-align: middle;
  padding: 0.5rem 0;
  color: $color-main;
}
.about dd {
  line-height: 1.8rem;
  border-bottom: 1px solid $color-main;
  padding:0.5rem 0;
  padding-left: 15%;
}
.about .br {
  display: none;
}
.about h4 {
    border-left: 10px solid $color-main;
    padding: 0.5rem 1rem;
    margin-bottom: 2rem;
}
.about ul {
  display: flex;
  flex-wrap: wrap;
}
.about li {
  width: 25%;
  margin-right: 12%;
  margin-bottom: 2rem;
  font-size: 12px;
}
.about li:nth-child(3) {
  margin-right: 0;
}
.about li:nth-child(6) {
  margin-right: 0;
}
.about li figure {
  width: 100%;
  border-radius: 1000px;
  margin-bottom: 1rem;
  background: $color-main;
  a {
      transition: .3s all;
  }
  a:hover {
    opacity: 0.5;
  }
}
.about img {
  width: 100%;
  opacity: 1;
}
.about_photobook {
  display: flex;
  justify-content: space-between;
}
.photobook img,.photobook2 img {
    width: 100%;;
}
.photobook,.photobook2 {
  width: 40%;
}

@media (max-width: 768px) {
.about .br {
  display: block;
}
.about li {
  width: 45%;
  margin-right: 5%;
  margin-bottom: 2rem;
  font-size: 12px;
}
.about li:nth-child(3) {
  margin-right: 5%;
}
.about li:nth-child(6) {
  margin-right: 5%;
}
.about li:nth-child(2) {
  margin-right: 0%;
}
.about li:nth-child(4) {
  margin-right: 0%;
}
.about li:nth-child(6) {
  margin-right: 0;
}
}
@media (max-width: 640px) {
.hero_top_about {
  height: 10rem;
}
.hero_top_about h1 span {
  line-height: 1.6rem;
  font-size: 1rem;
}
.about dt {
  float: none;
  padding: 0.2rem 0;
  color: $color-main;
  border-bottom: 1px dotted $color-main;
  width: 100%;
}
.about dd {
  padding: 0.2rem 0;
  border: none;
  margin-bottom: 1rem;
}
.about_photobook {
  display: block;
}
.photobook,.photobook2 {
  width: 100%;
}
.photobook img,.photobook2 img {
  width: 100%;
}
.photobook {
  margin-bottom: 2rem;
}
}
