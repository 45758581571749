/*-------------------------------------
ブログ用アーカイブ
------------------------------------*/
.hero_top_archive {
  background-image: url(img/hero/06.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 320px
}
@media (max-width: 640px){
  .hero_top_archive{
    height: 10rem;
    h1 span {
      font-size: 1rem;
    }
  }
}
.hero_top_archive_news {
  background-image: url(img/hero/16.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 320px;
}
@media (max-width: 640px){
.hero_top_archive_news{
    height: 10rem;
    h1 span {
      font-size: 1rem;
    }
  }
}
.hero_top_archive h1,.hero_top_archive_news h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  letter-spacing: 0.2rem;
  color: #fff;
  text-align: center;
  line-height: 3.5rem;
  font-family: 'Caveat Brush', cursive;
  span {
    font-size: 1.2rem;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  }
}
@media (max-width: 640px){
.hero_top_archive{
    height: 10rem;
    h1 span {
      font-size: 1rem;
    }
  }
}
.archive_scale {
    max-width: 100%;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
}
.archive_scale img {
    transition: transform 0.2s linear;
    width: 100%;
}
.archive_scale img:hover {
    filter: grayscale(100%);
}
.blog_list {
  width: 64%;
  margin: 0 auto;
  a {
    color: #333;
    text-decoration: none;
    display: block;
  }
  a:hover {

  }
}
.blog_kiji {
    box-shadow: 1px 1px 8px #ccc;
    margin-bottom: 4rem;
    padding: 2rem;
}
.blog_kiji img {
  width: 100%;
  height: auto;
}
.blog_kiji h1 {
  padding: 1rem 0;
  font-size: 1.4rem;
  line-height: 2.5rem;
}
.blog_kiji time {
  display: block;
  font-size: 13px;
}


@media (max-width: 768px){
  .blog_list {
    width: 100%;
    padding: 2rem 2rem 0 2rem;
  }
  .blog_kiji {
    margin-bottom: 2rem;
  }
  .blog_kiji h1 {
    font-size: 1.2rem;
    line-height: 2rem;
  }
  .hero_top_archive {
    height: 30%;
  }
  .hero_top_archive_news {
    height: 30%;
  }
}
/*-------------------------------------
お知らせ用アーカイブ
------------------------------------*/
.news_kiji {
  border-top: 1px solid #11a4bf;
  padding: 2rem 0;
}
.news_kiji:last-child {
  border-bottom: 1px solid #11a4bf;
}
a.news_kiji_link {
  text-decoration: underline;
  font-size: 1rem;
  padding-top:1rem;
  &:hover {
    opacity: 0.8;
  }
}
.news_kiji_link h1 {
  font-size: 1rem;
  line-height: 1.8rem;
}
.news_kiji time {
  font-size: 0.8rem;
}
.news_kiji button {
    padding: 0;
    margin-left: 1rem;
    font-size: 0.6rem;
    border: none;
    a {
      padding: 0.2rem 1rem;
      color: #fff;
      display: block;
      background: #11a4bf;
      &:hover {
        opacity: 0.8;
      }
    }
}

/*-------------------------------------
ムービー用アーカイブ
------------------------------------*/
.movie_wrapper {
  padding: 4rem;
}
.movie_wrapper h3 {
    text-align: center;
    font-size: 1.5rem;
    padding-bottom: 2rem;
    letter-spacing: 0.2rem;
}

.movie_wrapper p {
    padding-bottom: 2rem;
    margin: 0 auto;
}
.movie_discription {
  position: relative;
  background: $color-gray;
  border-radius: 10px;
  padding: 1.5rem;
  margin: 1rem 0;
}
.movie_discription p {
  line-height: 1.3rem;
  position: relative;
  padding-bottom: 0;
}
.movie_discription_title {
  position: absolute;
  top: -20px;
  left: 14px;
  transform: rotate(-10deg);
  color: $color-main;
  font-size: 2rem;
  letter-spacing: 0.05rem;

}
.movie_sidebar {
  width: 100%;
}
.movie_archive {
  padding-top: 2rem;
}
.movie_contents:nth-child(3n) {
  margin-right:0;
}
.cf:after {
  content: "";
  clear: both;
  display: block;
}
.scale2 {
    max-height: 200px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
}
.scale2 img {
    transition: transform 0.2s linear;
    width: 100%;
}
.scale2 img:hover {
    transform: scale(1.05);
}
.movie_contents {
  max-width: 300px;
  float: left;
  margin-right: 7%;
  margin-bottom: 2rem;
  img {
    width: 100%;
  }
}
.movie_contents p {
  padding-top: 0.5rem;
  width: 100%;
}
.movie_contents a {
    text-decoration: none;
    color: $color-dark;
    line-height: 1.5rem;
    &:hover {
      text-decoration: underline;
    }
}
.page-area {
    position: relative;
    width: 100%;
    height: 2rem;
}
.pagination{
    color: $color-main;
    line-height:2em;
    text-align:center;
    clear: both;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
    display: flex;
}
.pagination a{
    text-decoration:none;
    padding: 0.25rem 0.7rem;
    margin-left: 0.5rem;
    border: 1px solid $color-main;
    color: $color-main;
    &:hover {
      background: $color-main;
      color: #fff;
    }
}
.pagination span {
  padding: 0.25rem 0.7rem;
  margin-left: 0.5rem;
  border: 1px solid $color-main;
  color: #fff;
  background-color: $color-main;
}
.movie_sidebar form label {
    display: none;
}

.movie_sidebar form select {
    border: 1px solid $color-main;
    margin: 2rem 0;
    padding: 0.5rem 3rem;
    border-radius: 10px;
}

.movie_sidebar form {
    text-align: center;
    overflow: hidden;
}
.movie_sidebar select{
    outline:none;
    text-indent: 0.01px;
    text-overflow: '';
    background: none transparent;
    vertical-align: middle;
    font-size: inherit;
    color: inherit;
    appearance: button;
    position: relative;
}
.movie_sidebar select option{
  background-color: #fff;
  color: $color-main;
}
.movie_sidebar select::-ms-expand {
  display: none;
}
.movie_sidebar select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #ccc;
}
.movie_sidebar select:before {
    z-index: 1;
    position: absolute;
    right: 15px;
    top: 0;
    content: "\f123";
    font-family: "IonIcons";
    line-height: 43px;
    color: #7F878C;
    pointer-events: none;
}
.movie_sidebar .widget-title {
  display: none;
}
