.hero_top_news {
  background-image: url(img/hero/09.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;;
  height: 320px;
}
.hero_top_news h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  letter-spacing: 0.2rem;
  color: #fff;
  text-align: center;
  line-height: 3.5rem;
  font-family: 'Caveat Brush', cursive;
  span {
    font-size: 1.2rem;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  }
}

@media (max-width: 768px) {

}
@media (max-width: 640px) {
.hero_top_news {
  height: 10rem;
  h1 span {
    font-size: 1rem;
  }
}
}
