/*------------hero------------*/
.hero {
  position: relative;
}
.player {
  position: absolute;
  top: 10%;
  left: 5%;
  z-index: 5;
  color: #fff;
}
.player a {
  color: #fff;
  text-decoration: none;
  vertical-align: middle;
  font-size: 16px;
  margin-left: 1rem;
}
@media (max-width: 768px){
  .player {
    display: none;
  }
}

.op {
min-width: 100%;
width: 100%;
height: auto;
z-index: -100;
background-size: cover;
}
.hero_sp {
  display: none;
}

/*------------grid layout------------*/
.item {
  padding: 3rem;
  background: #fff;
  position: relative;
  box-shadow: 0 3px 3px #ccc;
  margin: 30px;
}
.grid {
  background: $color-gray;
  display: -ms-grid;
  display: grid;
/*  grid-gap: 80px;*/
  -ms-grid-colums:1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows:660px 660px 350px 250px auto;
  grid-template-rows: 660px 660px 350px 250px auto;
}
.grid .item1 {
  -ms-grid-column:1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
}
.grid .item2 {
  -ms-grid-column:2;
  grid-column: 2;
  -ms-grid-row: 1;
  grid-row: 1;
}
.grid .item3 {
  -ms-grid-column:1;
  grid-column: 1;
  -ms-grid-row: 2;
  grid-row: 2;
}
.grid .item4 {
  -ms-grid-column:2;
  grid-column: 2;
  -ms-grid-row: 2;
  grid-row: 2;
}
.grid .box-big1 {
-ms-grid-column: 1;
grid-column: 1;
-ms-grid-row:3;
-ms-grid-row-span: 2;
 grid-row: 3 / 5;
}
.grid .item6 {
  -ms-grid-column:2;
  grid-column: 2;
  -ms-grid-row: 3;
  grid-row: 3;
}
.grid .item7 {
  -ms-grid-column:2;
  grid-column: 2;
  -ms-grid-row: 4;
  grid-row: 4;
}
.grid .box-big2 {
  -ms-grid-column: 1;
  -ms-grid-column-span:2;
  -ms-grid-row:5;
  grid-row: 5;
 grid-column: 1 / 3;
}
@media (max-width: 768px){
  .grid {
    -ms-grid-colums: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    .item1, .item2, .item3, .item4, .item6, .item7,.box-big1, .box-big2 {
      -ms-grid-column: 1;
      grid-column: 1;
      -ms-grid-row: auto;
      grid-row: auto;
    }
  }
}
/*------------top menu  -----------------*/
.point {
  position: absolute;
  top: -36px;
  left: 50%;
  transform: translate(-50%,0);
  text-align: center;
}
div.point img {
  width: 60%;
  box-shadow: 0 0 3px #ccc;
  border-radius: 100px;
}
/*------------ぱしゃりごとって何？-----------------*/
.item h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    font-family: 'Caveat Brush', cursive;
}
.grid h2 span {
    display: block;
    font-size: 0.8rem;
    margin-top: 0.8rem;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
}

.what {
  text-align: center;
}
.what p {
    line-height: 1.5rem;
    letter-spacing: 0.05rem;
    margin-bottom: 2rem;
    text-align: left;
}
.what img {
    display: block;
    margin: 0 auto;
    margin-bottom: 2rem;
    width: 40%;
}
.what p {
  font-weight: bold;
  margin-bottom: 1.5rem;
  display: block;
  font-size: 0.95rem;
}
strong {
    background: linear-gradient(transparent 40%, #ffff66 40%);
}
.top_concept_btn {
  margin-bottom:  1rem;
}
.btn1 {
    padding: 0;
    border: none;
    font-size: 14px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 1rem;
}
  .btn1 a {
        width: 100%;
        display: block;
        overflow: hidden;
        transition: all 0.3s;
        margin: 0 auto;
        border: 1px solid $color-main;
        background: #fff;
        color: $color-main;
        display: block;
        padding: 1rem 0;
        color: $color-main;
        text-decoration: none;
        &:hover {
          background: $color-main;
          color: #fff;
    }
  }
/*------------１分で〇〇ムービー-----------------*/
.top_movie {
  li {
  margin: 0 auto;
  margin-bottom: 3rem;
  width: 50%;
  text-align: center;
  line-height: 1.5rem;
  }
  .frame {
    width: 100%;
    background: rgba(0,0,0,0.03);
  }
  li a {
    text-decoration: none;
    color: $color-dark;
    display: block;
    transition: all 0.3s;
    &:hover img {
      background: $color-main;
      transition: all 0.3s;
    }
  }
}
@media (max-width: 1080px) {
  .top_movie li {
    width: 70%;
  }
}
@media (max-width: 640px) {
  .top_movie {
    display: block;
    li {
      width: 100%;
    }
    .frame {
      width: 70%;
    }
  }
}
/*------------ブログー-----------------*/
.top_blog a {
    text-decoration: none;
    display: block;
}
.top_blog a:hover {
  text-decoration: underline;
  color: $color-dark;
}
.blog_text {
    line-height: 1.5rem;
    color: #212121;
    max-width: 400px;
    margin: 0 auto;
}
.blog-text a p {
  text-decoration: underline;
  color: #999;
  font-size: 0.8rem;
}
.scale {
    max-width: 400px;
    max-height: 250px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
}
.scale img {
    transition: transform 0.2s linear;
    width: 100%;
}
.scale img:hover {
    transform: scale(1.05);
}
.top_blog {
    margin-bottom: 2rem;
}

.btn2 {
    position: relative;
    z-index: 2;
    font-size: 100%;
    padding: 0;
    width: 80%;
    border: 1px solid $color-main;
    background: #fff;
    color: $color-main;
    display: block;
    overflow: hidden;
    transition: all 0.3s;
    margin: 0 auto;
    margin-top: -1rem;
    text-align: center;
}
.btn2 a {
    display: block;
    padding: 1rem 0;
    color: $color-main;
    text-decoration: none;
    transition: all 0.3s;
}
.btn2 a:hover {
      background: $color-main;
      color: #fff;
      &:after {
        top:0;
        left: 0;
        background: #fff;
        color: $color-main;
      }
    }
    .btn3 {
      margin-top: 2rem;
    }
/*------------メンバーー-----------------*/
.top_member, .top_about {
    width: 100%;
    height: 100%;
}

.list {
    width: 100%;
    height: 25%;
    margin-bottom: 15px;
}
@media (max-width: 768px) {
  .list {
    height: 8rem;
  }
}
.list1 {
    text-decoration: none;
    display: block;
    position: relative;
    width: 100%;
    height:100%;
    background-image: url(img/top_prof_photo1.jpg);
    background-size: cover;
    filter: grayscale(100%);
    &:hover {
    filter: grayscale(0%);
    }
}
.list2 {
    text-decoration: none;
    display: block;
    position: relative;
    width: 100%;
    height:100%;
    background-image: url(img/top_prof_photo2.jpg);
    background-size: cover;
    filter: grayscale(100%);
    &:hover {
    filter: grayscale(0%);
    }
}
.list3 {
    text-decoration: none;
    display: block;
    position: relative;
    width: 100%;
    height:100%;
    background-image: url(img/top_prof_photo3.jpg);
    background-size: cover;
    filter: grayscale(100%);
    &:hover {
    filter: grayscale(0%);
    }
}
@media (max-width: 768px) {
  .list1 {
    filter: grayscale(0%);
  }
  .list2 {
    filter: grayscale(0%);
  }
  .list3 {
    filter: grayscale(0%);
  }
}
.member_name {
  position: absolute;
  top: 20%;
  left: 10%;
  color: $color-dark;
  font-size: 3rem;
  transform: rotate(-10deg);
}
.member_position {
  position: absolute;
  top: 50%;
  left: 50%;
  color: $color-dark;
  letter-spacing: 0.1rem;
  transform: translate(-50%,-50%);
  color: #fff;
}
.master {
  color: #212121;
}
@media (max-width: 768px) {
  .member_name {
    font-size: 2rem;
    top: 10%;
    left: 5%;
  }
}
/*------------会社概要-----------------*/
.about_position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: $color-dark;
  letter-spacing: 0.1rem;
  font-size: 0.8rem;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.1rem;
}
.top_about .list {
    width: 100%;
    height: 40%;
    margin-bottom: 20px;
}
@media (max-width: 768px) {
  .top_about .list {
    height: 8rem;
  }
}
.list4 {
    text-decoration: none;
    display: block;
    position: relative;
    width: 100%;
    height:100%;
    background-image: url(img/about_btn1.jpg);
    background-size: cover;
    background-position: center;
    filter: grayscale(100%);
    &:hover {
    filter: grayscale(0%);
    }
}
.list5 {
    text-decoration: none;
    display: block;
    position: relative;
    width: 100%;
    height:100%;
    background-image: url(img/about_btn2.jpg);
    background-size: cover;
    background-position: center;
    filter: grayscale(100%);
    &:hover {
    filter: grayscale(0%);
    }
}
@media (max-width: 768px) {
  .list4 {
    filter: grayscale(0%);
  }
  .list5 {
    filter: grayscale(0%);
  }
}
/*------------みんなのぱしゃりごと-----------------*/
.top_everybody {text-align: center;}

.top_everybody li {
    border-top: 1px dotted #333;
}
.top_everybody li:last-child {
    border-bottom: 1px dotted #333;
}

.top_everybody li a {
    text-decoration: none;
    color: #333;
    padding: 1rem;
    display: block;
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
    &:hover {
      background: $color-gray;
    }
}
/*------------お知らせ-----------------*/
.news_content {
  display: flex;
  justify-content: space-between;
}
.news_more {
    border: none;
    border: 1px solid $color-main;
    padding: 0;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    transition: all 0.3s;
     a {
       text-decoration: none;
       color: $color-main;
       padding: 1rem 3rem;
       display: block;
       width: 100%;
       height: 100%;
       vertical-align: middle;
       line-height: 110px;
       font-size: 0.9rem;
       transition: all 0.3s;
       text-align: center;
     }
     a:hover {
       background: $color-main;
       color: #fff;
     }
}

.news h2 span {
  display: block;
}
.news li time {
    display: inline-block;
    line-height: 1.5rem;
}

.news li a {
    text-decoration: none;
    color: #333;
    padding: 0.8rem 2rem 0.8rem 2rem;
    display: block;
    &:hover {
      background: #eee;
    }
}
@media (max-width: 768px) {
  .news li a {
    padding: 0 0 1rem 0;
  }
}
.news li p {
    display: inline-block;
    margin-left: 2rem;
}
@media (max-width: 960px) {
  .news_content {
    display: block;
    .news_right .news_more {
      height: auto;
      margin-top: 1rem;
      a {
        line-height: 0;
        height: inherit;
        background: #fff;
      }
    }
  }
  .news li time {
    display: block;
  }
  .news li p {
    margin-left: 0;
    line-height: 1.5rem;
  }
}
/*------------hero メディアクエリ----------------*/
@media (max-width: 640px) {
  .hero {
    display: none;
  }
  .hero_sp {
    display: block;
    .op_sp {
      width: 100%;
    }
  }
}
.hp {
  color: red;
}

@media (max-width: 768px) {
  .scale {
    .scale {
        max-width: 100%;
        height: auto;
        overflow: hidden;
        margin: 0 auto;
        position: static;
    }
    img {
        transition: none;
        width: 100%;
    }
    img:hover {
        transform: none;
    }
  }
.info-thumbnail {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.info-thumbnail img {
    width: 100%;
    height: auto;
}
}
