.hero_top_blog {
  background-image: url(img/hero/06.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 320px;
}
.hero_top_news {
  background-image: url(img/blog3.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 320px;
}
.hero_top_blog h1,.hero_top_news h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  letter-spacing: 0.2rem;
  color: #fff;
  text-align: center;
  line-height: 3.5rem;
  font-family: 'Caveat Brush', cursive;
  span {
    font-size: 1.2rem;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  }
}
@media (max-width: 640px){
  .hero_top_blog {
    height: 10rem;
    h1 span {
      font-size: 1rem;
    }
  }
    .hero_top_news {
      height: 10rem;
      h1 span {
        font-size: 1rem;
      }
    }
}
.wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 4rem 0;
}
.blog_seal {
  position: relative;
}
.point {
  position: absolute;
  top: -36px;
  left: 50%;
  transform: translate(-50%,0);
}
div.point img {
  width: 60%;
  box-shadow: 0 0 3px #ccc;
  border-radius: 100px;
}
.blog_contents {
  width: 70%;
  padding: 0 4rem;
}
.blog_contents p {
    width: 100%;
    word-wrap: break-word;
}
.sidebar {
  width: 30%;
}
.blog_contents h1 {
  padding: 2rem 0;
  border-top: 1px solid $color-main;
  border-bottom: 1px solid $color-main;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  line-height: 2.5rem;
}
.blog_contents time {
  display: block;
  font-size: 13px;
  margin-bottom: 2rem;
}
.blog_contents h2 {
  font-weight: bold;
  font-size: 1.1rem;
  text-align: left;
  margin: 2rem 0 1rem 0;
  letter-spacing: 0.1rem;
}
.blog_contents p {
    line-height: 1.5rem;
    padding-bottom: 1.2rem;
}
.blog_contents img {
  width: 100%;
  padding: 0 0 1rem 0;
  height: auto;
}

.pagenation ul {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
  li a {
    color: $color-dark;
  }
}
.back {
  border: none;
  padding: 0;
  width: 100%;
  text-align: center;
}
.back a {
  padding: 0;
  margin: 0 auto;
  text-align: center;
  padding: 0.5rem 3rem;
  background: #fff;
  border: 1px solid $color-main;
  color: $color-main;
  display: inline-block;
  transition: all 0.3s;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    background: $color-main;
    color: #fff;
  }
}
.post-navigation {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
}
.prev, .next {
  color: $color-main;
}
.prev a, .next a {
    text-decoration: none;
    color: $color-main;
    &:hover {
      text-decoration: underline;
    }
}
@media (max-width: 768px){
  .hero_top_news {
    height: 30%;
  }
  .hero_top_blog {
    height: 30%;
  }
  .wrapper {
    display: block;
    padding: 4rem 0 2rem 0;
  }
  .blog_contents {
    width: 100%;
    padding: 0 2rem;
  }
  .sidebar {
    width: 90%;
    margin: 0 auto;
    margin-top: 3rem;
  }
  .hero_top_blog h1 {
    font-size: 3rem;
    span {
      font-size: 0.9rem;
    }
  }
  .hero_top_news h1 {
    font-size: 3rem;
    span {
      font-size: 0.9rem;
    }
  }
  .blog_contents h1 {
    padding: 1rem 0;
    font-size: 1.3rem;
  }
  .blog_contents time {
    margin-bottom: 1rem;
  }
  .back a {
    width: 100%;
  }
}



/*-------------ムービーシングルページ----------------*/
.movie_single_contents {
  width: 100%;
  padding: 0 4rem;
}
iframe {
    width: 560px;
    height: 315px;
    margin: 0 auto;
    display: block;
}
@media (max-width: 768px){
  .movie_single_contents {
    padding: 0 2rem;
  }
}
@media (max-width: 640px){
  iframe {
      width: 100%;
      height: 300px;
  }
}
