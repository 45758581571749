
.movie_sidebar ul {
    display: flex;
    justify-content: space-around;
}

.movie_sidebar {
    width: 60%;
    margin: 0 auto;
}
.movie_sidebar li {
  width: 200px;
}
.movie_sidebar li a {
    padding: 0.4rem 0;
    display: block;
    text-decoration: none;
    border: 1px solid $color-main;
    border-radius: 100px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    color: $color-main;
    max-width: 200px;
    text-align: center;
    &:hover {
      background: $color-main;
      color: #fff;
    }
}
.movie_sidebar li span {
  padding: 0.7rem 0;
  display: block;
  border: 1px solid $color-main;
  border-radius: 100px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: #fff;
  background: $color-main;
  max-width: 200px;
  text-align: center;
}

@media (max-width: 1200px){
  .movie_contents {
    max-width: 46%;
    margin-right: 8%;
  }
  .movie_contents:nth-child(3n) {
    margin-right:8%;
  }
  .movie_contents:nth-child(2n){
    margin-right: 0;
  }
  .scale2 {
      max-height: auto;
      margin: 0 auto;
      position: static;
      height: auto;
  }
  .scale2 img {
      transition: none;
      width: 100%;
      height: auto;
  }
  .scale2 img:hover {
      transform: none;
  }
}
@media (max-width: 960px){
.movie_sidebar {
  width: 100%;
}
}
@media (max-width: 768px){
.movie_wrapper p {
  width: 100%;
}
}
@media (max-width: 640px){
  .movie_contents {
    float: none;
    max-width: 100%;
    margin: 0;
  }
  .movie_wrapper {
    padding: 4rem 2rem;
  }
  .movie_contents:nth-child(3n){
    margin-right: 0;
  }
  .movie_sidebar li {
    width: 120px;
  }
}
