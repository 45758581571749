@charset "UTF-8";
/*
  Theme Name: Pasha
  Theme URI: http://example.com/
  Author: maico
  Author URI: http://example.com/
  Description: Description
  Version: 1.0.0
  License:
  License URI:
  Text Domain: Pasha-wordpress
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/*  HTML5 display-role reset for older browsers
*/
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 0;
  background: none transparent;
  vertical-align: middle;
  font-size: inherit;
  color: inherit;
  box-sizing: content-box;
}

/*-----------------------------
全体構造
-----------------------------*/
html {
  height: 100%;
}

body {
  height: 100%;
  background: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  font-size: 14px;
  color: #212121;
}

* {
  box-sizing: border-box;
}

/*-----------------------------
カラー
-----------------------------*/
/*-----------------------------
フォント
-----------------------------*/
.fsz14 {
  font-size: 14px;
}

.fsz18 {
  font-size: 18px;
}

.ff-hand {
  font-family: 'Caveat Brush', cursive;
}

.ff-hikki {
  font-family: 'Sacramento', cursive;
}

/*-----------------------------
デバイス対応
-----------------------------*/
.sp {
  display: none;
}

@media (max-width: 768px) {
  .sp {
    display: block;
  }
  .pc {
    display: none;
  }
}

/*-----------------------------
パーツ
-----------------------------*/
.main {
  background: #efefef;
  padding: 5rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
}

@media (max-width: 1200px) {
  .container {
    width: 90%;
    margin: 0 auto;
  }
}

.hero_top {
  width: 100%;
  height: 320px;
}

@media (max-width: 768px) {
  .hero_top {
    width: 100%;
    height: 30%;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  display: none;
  background-color: rgba(255, 255, 255, 0.8);
  border-bottom: solid 1px silver;
}

/*
.header.scroll{
  background-color:rgba(255,255,255,0.8);
  border-bottom:solid 1px silver;
  transition-duration: 0.2s;
}*/
.header_contents {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 1rem 2rem;
}

.header_left h1 img {
  width: 70%;
}

/*PC用g-nav*/
.pc_nav_list {
  display: flex;
  justify-content: space-around;
}

.pc_nav_list {
  font-size: 1.5rem;
  letter-spacing: 0.05rem;
}

.pc_nav_list li a {
  padding: 1rem;
  color: #212121;
  text-decoration: none;
  vertical-align: middle;
}

.pc_nav_list li a:hover {
  color: #11a4bf;
}

@media (max-width: 960px) {
  .pc_nav_list {
    font-size: 1.2rem;
    letter-spacing: 0;
  }
}

.pc_nav_list li {
  line-height: 3rem;
}

@media (max-width: 768px) {
  .header_contents .pc {
    display: none;
  }
}

#pageTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

#pageTop a {
  display: block;
  z-index: 999;
  padding: 8px 8px;
  border-radius: 30px;
  width: 35px;
  height: 35px;
  background-color: #11a4bf;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
}

#pageTop a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.menu-trigger,
.menu-trigger span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  cursor: pointer;
}

.menu-trigger {
  position: relative;
  width: 50px;
  height: 50px;
  z-index: 2;
}

.menu-trigger span {
  position: absolute;
  background: #212121;
  width: 30px;
  height: 2px;
  left: 0;
  right: 0;
  margin: auto;
}

.menu-trigger span:nth-of-type(1) {
  top: 15px;
}

.menu-trigger span:nth-of-type(2) {
  top: 0;
  bottom: 0;
}

.menu-trigger span:nth-of-type(3) {
  bottom: 15px;
}

.menu-trigger.active span:nth-of-type(1) {
  -webkit-transform: translateY(9px) rotate(-45deg);
  transform: translateY(9px) rotate(-45deg);
  background: #fff;
}

.menu-trigger.active span:nth-of-type(2) {
  opacity: 0;
}

.menu-trigger.active span:nth-of-type(3) {
  -webkit-transform: translateY(-9px) rotate(45deg);
  transform: translateY(-9px) rotate(45deg);
  background: #fff;
}

.g-nav {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
}

.g-nav .toggle-list {
  padding-top: 5rem;
}

.g-nav .toggle-list .toggle-tem {
  border-bottom: 1px solid #fff;
}

.g-nav .toggle-list .toggle-item a {
  display: block;
  text-align: center;
  text-decoration: none;
  color: #fff;
  padding: 0.5rem 0;
}

.toggle-item a {
  font-family: 'Caveat Brush', cursive;
  font-size: 1.3rem;
  line-height: 1.8rem;
}

.toggle-item a span {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  display: block;
  font-size: 0.8rem;
}

.footer {
  padding: 2rem;
  text-align: center;
}

.footer img {
  width: 83px;
  margin-bottom: 2rem;
}

.footer_nav_list {
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.footer_nav_list li a {
  padding: 1rem;
  display: block;
  text-decoration: none;
  color: #333;
}

.footer_nav_list li a:after {
  content: "|";
  padding-left: 2rem;
}

.footer_nav_list li:first-child a:before {
  content: "|";
  padding-right: 2rem;
}

@media (max-width: 960px) {
  .footer_nav_list li a:after {
    content: "";
    padding-left: 0;
  }
  .footer_nav_list li:first-child a:before {
    content: "";
    padding-right: 0;
  }
}

@media (max-width: 640px) {
  .footer_nav_list {
    display: block;
    line-height: 1.2rem;
  }
  .footer_nav_list li a {
    padding: 0.4rem;
  }
}

/*------------hero------------*/
.hero {
  position: relative;
}

.player {
  position: absolute;
  top: 10%;
  left: 5%;
  z-index: 5;
  color: #fff;
}

.player a {
  color: #fff;
  text-decoration: none;
  vertical-align: middle;
  font-size: 16px;
  margin-left: 1rem;
}

@media (max-width: 768px) {
  .player {
    display: none;
  }
}

.op {
  min-width: 100%;
  width: 100%;
  height: auto;
  z-index: -100;
  background-size: cover;
}

.hero_sp {
  display: none;
}

/*------------grid layout------------*/
.item {
  padding: 3rem;
  background: #fff;
  position: relative;
  box-shadow: 0 3px 3px #ccc;
  margin: 30px;
}

.grid {
  background: #efefef;
  display: -ms-grid;
  display: grid;
  /*  grid-gap: 80px;*/
  -ms-grid-colums: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 660px 660px 350px 250px auto;
  grid-template-rows: 660px 660px 350px 250px auto;
}

.grid .item1 {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
}

.grid .item2 {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 1;
  grid-row: 1;
}

.grid .item3 {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 2;
  grid-row: 2;
}

.grid .item4 {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 2;
  grid-row: 2;
}

.grid .box-big1 {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 2;
  grid-row: 3 / 5;
}

.grid .item6 {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 3;
  grid-row: 3;
}

.grid .item7 {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 4;
  grid-row: 4;
}

.grid .box-big2 {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  -ms-grid-row: 5;
  grid-row: 5;
  grid-column: 1 / 3;
}

@media (max-width: 768px) {
  .grid {
    -ms-grid-colums: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  .grid .item1, .grid .item2, .grid .item3, .grid .item4, .grid .item6, .grid .item7, .grid .box-big1, .grid .box-big2 {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: auto;
    grid-row: auto;
  }
}

/*------------top menu  -----------------*/
.point {
  position: absolute;
  top: -36px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
}

div.point img {
  width: 60%;
  box-shadow: 0 0 3px #ccc;
  border-radius: 100px;
}

/*------------ぱしゃりごとって何？-----------------*/
.item h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  font-family: 'Caveat Brush', cursive;
}

.grid h2 span {
  display: block;
  font-size: 0.8rem;
  margin-top: 0.8rem;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
}

.what {
  text-align: center;
}

.what p {
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
  margin-bottom: 2rem;
  text-align: left;
}

.what img {
  display: block;
  margin: 0 auto;
  margin-bottom: 2rem;
  width: 40%;
}

.what p {
  font-weight: bold;
  margin-bottom: 1.5rem;
  display: block;
  font-size: 0.95rem;
}

strong {
  background: linear-gradient(transparent 40%, #ffff66 40%);
}

.top_concept_btn {
  margin-bottom: 1rem;
}

.btn1 {
  padding: 0;
  border: none;
  font-size: 14px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.btn1 a {
  width: 100%;
  display: block;
  overflow: hidden;
  transition: all 0.3s;
  margin: 0 auto;
  border: 1px solid #11a4bf;
  background: #fff;
  color: #11a4bf;
  display: block;
  padding: 1rem 0;
  color: #11a4bf;
  text-decoration: none;
}

.btn1 a:hover {
  background: #11a4bf;
  color: #fff;
}

/*------------１分で〇〇ムービー-----------------*/
.top_movie li {
  margin: 0 auto;
  margin-bottom: 3rem;
  width: 50%;
  text-align: center;
  line-height: 1.5rem;
}

.top_movie .frame {
  width: 100%;
  background: rgba(0, 0, 0, 0.03);
}

.top_movie li a {
  text-decoration: none;
  color: #212121;
  display: block;
  transition: all 0.3s;
}

.top_movie li a:hover img {
  background: #11a4bf;
  transition: all 0.3s;
}

@media (max-width: 1080px) {
  .top_movie li {
    width: 70%;
  }
}

@media (max-width: 640px) {
  .top_movie {
    display: block;
  }
  .top_movie li {
    width: 100%;
  }
  .top_movie .frame {
    width: 70%;
  }
}

/*------------ブログー-----------------*/
.top_blog a {
  text-decoration: none;
  display: block;
}

.top_blog a:hover {
  text-decoration: underline;
  color: #212121;
}

.blog_text {
  line-height: 1.5rem;
  color: #212121;
  max-width: 400px;
  margin: 0 auto;
}

.blog-text a p {
  text-decoration: underline;
  color: #999;
  font-size: 0.8rem;
}

.scale {
  max-width: 400px;
  max-height: 250px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}

.scale img {
  transition: transform 0.2s linear;
  width: 100%;
}

.scale img:hover {
  transform: scale(1.05);
}

.top_blog {
  margin-bottom: 2rem;
}

.btn2 {
  position: relative;
  z-index: 2;
  font-size: 100%;
  padding: 0;
  width: 80%;
  border: 1px solid #11a4bf;
  background: #fff;
  color: #11a4bf;
  display: block;
  overflow: hidden;
  transition: all 0.3s;
  margin: 0 auto;
  margin-top: -1rem;
  text-align: center;
}

.btn2 a {
  display: block;
  padding: 1rem 0;
  color: #11a4bf;
  text-decoration: none;
  transition: all 0.3s;
}

.btn2 a:hover {
  background: #11a4bf;
  color: #fff;
}

.btn2 a:hover:after {
  top: 0;
  left: 0;
  background: #fff;
  color: #11a4bf;
}

.btn3 {
  margin-top: 2rem;
}

/*------------メンバーー-----------------*/
.top_member, .top_about {
  width: 100%;
  height: 100%;
}

.list {
  width: 100%;
  height: 25%;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .list {
    height: 8rem;
  }
}

.list1 {
  text-decoration: none;
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(img/top_prof_photo1.jpg);
  background-size: cover;
  filter: grayscale(100%);
}

.list1:hover {
  filter: grayscale(0%);
}

.list2 {
  text-decoration: none;
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(img/top_prof_photo2.jpg);
  background-size: cover;
  filter: grayscale(100%);
}

.list2:hover {
  filter: grayscale(0%);
}

.list3 {
  text-decoration: none;
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(img/top_prof_photo3.jpg);
  background-size: cover;
  filter: grayscale(100%);
}

.list3:hover {
  filter: grayscale(0%);
}

@media (max-width: 768px) {
  .list1 {
    filter: grayscale(0%);
  }
  .list2 {
    filter: grayscale(0%);
  }
  .list3 {
    filter: grayscale(0%);
  }
}

.member_name {
  position: absolute;
  top: 20%;
  left: 10%;
  color: #212121;
  font-size: 3rem;
  transform: rotate(-10deg);
}

.member_position {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #212121;
  letter-spacing: 0.1rem;
  transform: translate(-50%, -50%);
  color: #fff;
}

.master {
  color: #212121;
}

@media (max-width: 768px) {
  .member_name {
    font-size: 2rem;
    top: 10%;
    left: 5%;
  }
}

/*------------会社概要-----------------*/
.about_position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #212121;
  letter-spacing: 0.1rem;
  font-size: 0.8rem;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.1rem;
}

.top_about .list {
  width: 100%;
  height: 40%;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .top_about .list {
    height: 8rem;
  }
}

.list4 {
  text-decoration: none;
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(img/about_btn1.jpg);
  background-size: cover;
  background-position: center;
  filter: grayscale(100%);
}

.list4:hover {
  filter: grayscale(0%);
}

.list5 {
  text-decoration: none;
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(img/about_btn2.jpg);
  background-size: cover;
  background-position: center;
  filter: grayscale(100%);
}

.list5:hover {
  filter: grayscale(0%);
}

@media (max-width: 768px) {
  .list4 {
    filter: grayscale(0%);
  }
  .list5 {
    filter: grayscale(0%);
  }
}

/*------------みんなのぱしゃりごと-----------------*/
.top_everybody {
  text-align: center;
}

.top_everybody li {
  border-top: 1px dotted #333;
}

.top_everybody li:last-child {
  border-bottom: 1px dotted #333;
}

.top_everybody li a {
  text-decoration: none;
  color: #333;
  padding: 1rem;
  display: block;
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
}

.top_everybody li a:hover {
  background: #efefef;
}

/*------------お知らせ-----------------*/
.news_content {
  display: flex;
  justify-content: space-between;
}

.news_more {
  border: none;
  border: 1px solid #11a4bf;
  padding: 0;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}

.news_more a {
  text-decoration: none;
  color: #11a4bf;
  padding: 1rem 3rem;
  display: block;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  line-height: 110px;
  font-size: 0.9rem;
  transition: all 0.3s;
  text-align: center;
}

.news_more a:hover {
  background: #11a4bf;
  color: #fff;
}

.news h2 span {
  display: block;
}

.news li time {
  display: inline-block;
  line-height: 1.5rem;
}

.news li a {
  text-decoration: none;
  color: #333;
  padding: 0.8rem 2rem 0.8rem 2rem;
  display: block;
}

.news li a:hover {
  background: #eee;
}

@media (max-width: 768px) {
  .news li a {
    padding: 0 0 1rem 0;
  }
}

.news li p {
  display: inline-block;
  margin-left: 2rem;
}

@media (max-width: 960px) {
  .news_content {
    display: block;
  }
  .news_content .news_right .news_more {
    height: auto;
    margin-top: 1rem;
  }
  .news_content .news_right .news_more a {
    line-height: 0;
    height: inherit;
    background: #fff;
  }
  .news li time {
    display: block;
  }
  .news li p {
    margin-left: 0;
    line-height: 1.5rem;
  }
}

/*------------hero メディアクエリ----------------*/
@media (max-width: 640px) {
  .hero {
    display: none;
  }
  .hero_sp {
    display: block;
  }
  .hero_sp .op_sp {
    width: 100%;
  }
}

.hp {
  color: red;
}

@media (max-width: 768px) {
  .scale .scale {
    max-width: 100%;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
    position: static;
  }
  .scale img {
    transition: none;
    width: 100%;
  }
  .scale img:hover {
    transform: none;
  }
  .info-thumbnail {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  .info-thumbnail img {
    width: 100%;
    height: auto;
  }
}

.hero_top_blog {
  background-image: url(img/hero/06.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 320px;
}

.hero_top_news {
  background-image: url(img/blog3.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 320px;
}

.hero_top_blog h1, .hero_top_news h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  letter-spacing: 0.2rem;
  color: #fff;
  text-align: center;
  line-height: 3.5rem;
  font-family: 'Caveat Brush', cursive;
}

.hero_top_blog h1 span, .hero_top_news h1 span {
  font-size: 1.2rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
}

@media (max-width: 640px) {
  .hero_top_blog {
    height: 10rem;
  }
  .hero_top_blog h1 span {
    font-size: 1rem;
  }
  .hero_top_news {
    height: 10rem;
  }
  .hero_top_news h1 span {
    font-size: 1rem;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 4rem 0;
}

.blog_seal {
  position: relative;
}

.point {
  position: absolute;
  top: -36px;
  left: 50%;
  transform: translate(-50%, 0);
}

div.point img {
  width: 60%;
  box-shadow: 0 0 3px #ccc;
  border-radius: 100px;
}

.blog_contents {
  width: 70%;
  padding: 0 4rem;
}

.blog_contents p {
  width: 100%;
  word-wrap: break-word;
}

.sidebar {
  width: 30%;
}

.blog_contents h1 {
  padding: 2rem 0;
  border-top: 1px solid #11a4bf;
  border-bottom: 1px solid #11a4bf;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  line-height: 2.5rem;
}

.blog_contents time {
  display: block;
  font-size: 13px;
  margin-bottom: 2rem;
}

.blog_contents h2 {
  font-weight: bold;
  font-size: 1.1rem;
  text-align: left;
  margin: 2rem 0 1rem 0;
  letter-spacing: 0.1rem;
}

.blog_contents p {
  line-height: 1.5rem;
  padding-bottom: 1.2rem;
}

.blog_contents img {
  width: 100%;
  padding: 0 0 1rem 0;
  height: auto;
}

.pagenation ul {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
}

.pagenation ul li a {
  color: #212121;
}

.back {
  border: none;
  padding: 0;
  width: 100%;
  text-align: center;
}

.back a {
  padding: 0;
  margin: 0 auto;
  text-align: center;
  padding: 0.5rem 3rem;
  background: #fff;
  border: 1px solid #11a4bf;
  color: #11a4bf;
  display: inline-block;
  transition: all 0.3s;
  text-decoration: none;
}

.back a:hover {
  text-decoration: none;
  background: #11a4bf;
  color: #fff;
}

.post-navigation {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
}

.prev, .next {
  color: #11a4bf;
}

.prev a, .next a {
  text-decoration: none;
  color: #11a4bf;
}

.prev a:hover, .next a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .hero_top_news {
    height: 30%;
  }
  .hero_top_blog {
    height: 30%;
  }
  .wrapper {
    display: block;
    padding: 4rem 0 2rem 0;
  }
  .blog_contents {
    width: 100%;
    padding: 0 2rem;
  }
  .sidebar {
    width: 90%;
    margin: 0 auto;
    margin-top: 3rem;
  }
  .hero_top_blog h1 {
    font-size: 3rem;
  }
  .hero_top_blog h1 span {
    font-size: 0.9rem;
  }
  .hero_top_news h1 {
    font-size: 3rem;
  }
  .hero_top_news h1 span {
    font-size: 0.9rem;
  }
  .blog_contents h1 {
    padding: 1rem 0;
    font-size: 1.3rem;
  }
  .blog_contents time {
    margin-bottom: 1rem;
  }
  .back a {
    width: 100%;
  }
}

/*-------------ムービーシングルページ----------------*/
.movie_single_contents {
  width: 100%;
  padding: 0 4rem;
}

iframe {
  width: 560px;
  height: 315px;
  margin: 0 auto;
  display: block;
}

@media (max-width: 768px) {
  .movie_single_contents {
    padding: 0 2rem;
  }
}

@media (max-width: 640px) {
  iframe {
    width: 100%;
    height: 300px;
  }
}

.sidebar h2 {
  padding: 1rem;
  font-size: 1.1rem;
  background: #eee;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.sidebar li a {
  color: #333;
  display: block;
  line-height: 1.5rem;
}

.sidebar li a:hover {
  opacity: 0.8;
}

.blog_sidebar li :before {
  content: '▶︎';
  color: #ccc;
  font-size: 13px;
  display: inline-block;
  margin-right: 0.25rem;
}

.sidebar li {
  list-style: none;
}

@media (max-width: 768px) {
  .sidebar h2 {
    margin-right: 0;
  }
}

/*-------------------------------------
ブログ用アーカイブ
------------------------------------*/
.hero_top_archive {
  background-image: url(img/hero/06.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 320px;
}

@media (max-width: 640px) {
  .hero_top_archive {
    height: 10rem;
  }
  .hero_top_archive h1 span {
    font-size: 1rem;
  }
}

.hero_top_archive_news {
  background-image: url(img/hero/16.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 320px;
}

@media (max-width: 640px) {
  .hero_top_archive_news {
    height: 10rem;
  }
  .hero_top_archive_news h1 span {
    font-size: 1rem;
  }
}

.hero_top_archive h1, .hero_top_archive_news h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  letter-spacing: 0.2rem;
  color: #fff;
  text-align: center;
  line-height: 3.5rem;
  font-family: 'Caveat Brush', cursive;
}

.hero_top_archive h1 span, .hero_top_archive_news h1 span {
  font-size: 1.2rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
}

@media (max-width: 640px) {
  .hero_top_archive {
    height: 10rem;
  }
  .hero_top_archive h1 span {
    font-size: 1rem;
  }
}

.archive_scale {
  max-width: 100%;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}

.archive_scale img {
  transition: transform 0.2s linear;
  width: 100%;
}

.archive_scale img:hover {
  filter: grayscale(100%);
}

.blog_list {
  width: 64%;
  margin: 0 auto;
}

.blog_list a {
  color: #333;
  text-decoration: none;
  display: block;
}

.blog_kiji {
  box-shadow: 1px 1px 8px #ccc;
  margin-bottom: 4rem;
  padding: 2rem;
}

.blog_kiji img {
  width: 100%;
  height: auto;
}

.blog_kiji h1 {
  padding: 1rem 0;
  font-size: 1.4rem;
  line-height: 2.5rem;
}

.blog_kiji time {
  display: block;
  font-size: 13px;
}

@media (max-width: 768px) {
  .blog_list {
    width: 100%;
    padding: 2rem 2rem 0 2rem;
  }
  .blog_kiji {
    margin-bottom: 2rem;
  }
  .blog_kiji h1 {
    font-size: 1.2rem;
    line-height: 2rem;
  }
  .hero_top_archive {
    height: 30%;
  }
  .hero_top_archive_news {
    height: 30%;
  }
}

/*-------------------------------------
お知らせ用アーカイブ
------------------------------------*/
.news_kiji {
  border-top: 1px solid #11a4bf;
  padding: 2rem 0;
}

.news_kiji:last-child {
  border-bottom: 1px solid #11a4bf;
}

a.news_kiji_link {
  text-decoration: underline;
  font-size: 1rem;
  padding-top: 1rem;
}

a.news_kiji_link:hover {
  opacity: 0.8;
}

.news_kiji_link h1 {
  font-size: 1rem;
  line-height: 1.8rem;
}

.news_kiji time {
  font-size: 0.8rem;
}

.news_kiji button {
  padding: 0;
  margin-left: 1rem;
  font-size: 0.6rem;
  border: none;
}

.news_kiji button a {
  padding: 0.2rem 1rem;
  color: #fff;
  display: block;
  background: #11a4bf;
}

.news_kiji button a:hover {
  opacity: 0.8;
}

/*-------------------------------------
ムービー用アーカイブ
------------------------------------*/
.movie_wrapper {
  padding: 4rem;
}

.movie_wrapper h3 {
  text-align: center;
  font-size: 1.5rem;
  padding-bottom: 2rem;
  letter-spacing: 0.2rem;
}

.movie_wrapper p {
  padding-bottom: 2rem;
  margin: 0 auto;
}

.movie_discription {
  position: relative;
  background: #efefef;
  border-radius: 10px;
  padding: 1.5rem;
  margin: 1rem 0;
}

.movie_discription p {
  line-height: 1.3rem;
  position: relative;
  padding-bottom: 0;
}

.movie_discription_title {
  position: absolute;
  top: -20px;
  left: 14px;
  transform: rotate(-10deg);
  color: #11a4bf;
  font-size: 2rem;
  letter-spacing: 0.05rem;
}

.movie_sidebar {
  width: 100%;
}

.movie_archive {
  padding-top: 2rem;
}

.movie_contents:nth-child(3n) {
  margin-right: 0;
}

.cf:after {
  content: "";
  clear: both;
  display: block;
}

.scale2 {
  max-height: 200px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}

.scale2 img {
  transition: transform 0.2s linear;
  width: 100%;
}

.scale2 img:hover {
  transform: scale(1.05);
}

.movie_contents {
  max-width: 300px;
  float: left;
  margin-right: 7%;
  margin-bottom: 2rem;
}

.movie_contents img {
  width: 100%;
}

.movie_contents p {
  padding-top: 0.5rem;
  width: 100%;
}

.movie_contents a {
  text-decoration: none;
  color: #212121;
  line-height: 1.5rem;
}

.movie_contents a:hover {
  text-decoration: underline;
}

.page-area {
  position: relative;
  width: 100%;
  height: 2rem;
}

.pagination {
  color: #11a4bf;
  line-height: 2em;
  text-align: center;
  clear: both;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
}

.pagination a {
  text-decoration: none;
  padding: 0.25rem 0.7rem;
  margin-left: 0.5rem;
  border: 1px solid #11a4bf;
  color: #11a4bf;
}

.pagination a:hover {
  background: #11a4bf;
  color: #fff;
}

.pagination span {
  padding: 0.25rem 0.7rem;
  margin-left: 0.5rem;
  border: 1px solid #11a4bf;
  color: #fff;
  background-color: #11a4bf;
}

.movie_sidebar form label {
  display: none;
}

.movie_sidebar form select {
  border: 1px solid #11a4bf;
  margin: 2rem 0;
  padding: 0.5rem 3rem;
  border-radius: 10px;
}

.movie_sidebar form {
  text-align: center;
  overflow: hidden;
}

.movie_sidebar select {
  outline: none;
  text-indent: 0.01px;
  text-overflow: '';
  background: none transparent;
  vertical-align: middle;
  font-size: inherit;
  color: inherit;
  appearance: button;
  position: relative;
}

.movie_sidebar select option {
  background-color: #fff;
  color: #11a4bf;
}

.movie_sidebar select::-ms-expand {
  display: none;
}

.movie_sidebar select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #ccc;
}

.movie_sidebar select:before {
  z-index: 1;
  position: absolute;
  right: 15px;
  top: 0;
  content: "\f123";
  font-family: "IonIcons";
  line-height: 43px;
  color: #7F878C;
  pointer-events: none;
}

.movie_sidebar .widget-title {
  display: none;
}

.concept_contents {
  display: Flex;
  justify-content: space-between;
}

.concept_wrapper {
  padding-bottom: 2rem;
  margin-bottom: 4rem;
}

.hero_top_concept {
  background-image: url(img/hero/01.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 320px;
}

.hero_top_concept h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  letter-spacing: 0.2rem;
  color: #fff;
  text-align: center;
  line-height: 3.5rem;
  font-family: 'Caveat Brush', cursive;
}

.hero_top_concept h1 span {
  font-size: 1.2rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
}

@media (max-width: 640px) {
  .hero_top_concept {
    height: 10rem;
  }
}

.concept_left {
  width: 70%;
  padding: 2rem 4rem;
}

.concept_left p {
  line-height: 1.4rem;
  letter-spacing: 0.05rem;
  margin-bottom: 1rem;
}

.concept_left p span {
  display: block;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  color: #11a4bf;
}

.concept_left p strong {
  background: linear-gradient(transparent 50%, #ffff66 30%);
}

.concept_right {
  padding: 2rem 4rem 2rem 0;
  width: 30%;
}

.concept_right img {
  width: 100%;
  box-shadow: 1px 1px 5px #ccc;
}

.concept_right p {
  padding-top: 1rem;
  font-size: 0.8rem;
  line-height: 1.5rem;
}

@media (max-width: 768px) {
  .concept_contents {
    display: block;
  }
  .concept_left {
    width: 100%;
    padding: 1rem 3rem;
  }
  .concept_right {
    width: 100%;
    padding: 1rem 3rem;
  }
}

@media (max-width: 640px) {
  .concept_left {
    padding: 1rem 2rem;
  }
  .concept_right {
    padding: 1rem 2rem;
  }
}

.movie_sidebar ul {
  display: flex;
  justify-content: space-around;
}

.movie_sidebar {
  width: 60%;
  margin: 0 auto;
}

.movie_sidebar li {
  width: 200px;
}

.movie_sidebar li a {
  padding: 0.4rem 0;
  display: block;
  text-decoration: none;
  border: 1px solid #11a4bf;
  border-radius: 100px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: #11a4bf;
  max-width: 200px;
  text-align: center;
}

.movie_sidebar li a:hover {
  background: #11a4bf;
  color: #fff;
}

.movie_sidebar li span {
  padding: 0.7rem 0;
  display: block;
  border: 1px solid #11a4bf;
  border-radius: 100px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: #fff;
  background: #11a4bf;
  max-width: 200px;
  text-align: center;
}

@media (max-width: 1200px) {
  .movie_contents {
    max-width: 46%;
    margin-right: 8%;
  }
  .movie_contents:nth-child(3n) {
    margin-right: 8%;
  }
  .movie_contents:nth-child(2n) {
    margin-right: 0;
  }
  .scale2 {
    max-height: auto;
    margin: 0 auto;
    position: static;
    height: auto;
  }
  .scale2 img {
    transition: none;
    width: 100%;
    height: auto;
  }
  .scale2 img:hover {
    transform: none;
  }
}

@media (max-width: 960px) {
  .movie_sidebar {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .movie_wrapper p {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .movie_contents {
    float: none;
    max-width: 100%;
    margin: 0;
  }
  .movie_wrapper {
    padding: 4rem 2rem;
  }
  .movie_contents:nth-child(3n) {
    margin-right: 0;
  }
  .movie_sidebar li {
    width: 120px;
  }
}

div.member_contents {
  width: 80%;
  padding: 2rem 4rem;
  margin: 0 auto;
}

div.member_contents h2 {
  font-weight: normal;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  vertical-align: top;
  letter-spacing: 0.1rem;
  border-bottom: 1px solid #11a4bf;
  line-height: 2rem;
}

div.member_contents h2 span {
  font-size: 0.9rem;
  margin-top: 0.5rem;
  display: block;
  font-weight: bold;
}

div.member_contents p {
  line-height: 1.4rem;
  margin-bottom: 1rem;
}

.hero_top_member {
  background-image: url(img/hero/07.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 320px;
}

.hero_top_member h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  letter-spacing: 0.2rem;
  color: #fff;
  text-align: center;
  line-height: 3.5rem;
  font-family: 'Caveat Brush', cursive;
}

.hero_top_member h1 span {
  font-size: 1.2rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
}

@media (max-width: 640px) {
  .hero_top_member {
    height: 10rem;
  }
  .hero_top_member h1 span {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.member_list ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.member_list li {
  max-width: 30%;
  text-align: center;
}

.member_list li img {
  width: 100%;
}

.member_list a {
  text-decoration: none;
  color: #11a4bf;
  font-size: 1rem;
  filter: grayscale(100%);
}

.member_list a:hover {
  filter: grayscale(0%);
}

.member_list h3 {
  margin-bottom: 1rem;
  margin-top: 2rem;
  line-height: 1.5rem;
}

.member_list h3 span {
  display: block;
  font-size: 13px;
  font-weight: bold;
}

@media (max-width: 640px) {
  .member_list ul {
    display: block;
  }
  .member_list li {
    max-width: 100%;
  }
  .member_list a {
    filter: grayscale(0%);
  }
}

@media (max-width: 640px) {
  .member_contents_layout {
    width: 100%;
  }
  div.member_contents {
    padding: 0 2rem;
  }
}

#list2, #list3 {
  padding-top: 10rem;
  margin-top: -8rem;
}

.member_contents_image1 {
  background: url(img/sunny_prof.jpg);
  background-size: cover;
  height: 520px;
  background-position: top center;
  width: 85%;
}

.member_contents_image2 {
  background: url(img/master_prof.jpg);
  background-size: cover;
  height: 400px;
  background-position: top center;
  width: 85%;
  margin-left: auto;
  margin-right: 0;
}

.member_contents_image3 {
  background: url(img/maicoo_prof.jpg);
  background-size: cover;
  height: 400px;
  background-position: top right;
  width: 85%;
  margin-left: auto;
  margin-right: 0;
}

.member_contents_list1 {
  width: 60%;
  padding: 2rem;
  margin-left: auto;
  margin-right: 0;
  margin-top: -450px;
  background: #11a4bf;
  box-shadow: 0 0 5px #999;
  color: #fff;
}

.member_contents_list2 {
  width: 55%;
  padding: 2rem;
  margin-top: -350px;
  background: #11a4bf;
  box-shadow: 0 0 5px #999;
  color: #fff;
}

.member_contents_list3 {
  width: 45%;
  padding: 2rem;
  margin-top: -350px;
  background: #11a4bf;
  box-shadow: 0 0 5px #999;
  color: #fff;
}

@media (max-width: 1200px) {
  .member_contents_list3 {
    width: 50%;
  }
}

@media (max-width: 1080px) {
  .member_contents_image1, .member_contents_image2, .member_contents_image3 {
    height: 300px;
    width: 100%;
  }
  .member_contents_image1 {
    background-position: top left;
  }
  .member_contents_image2 {
    background-position: top right;
  }
  .member_contents_image3 {
    background-position: top right;
  }
  .member_contents_list1, .member_contents_list2, .member_contents_list3 {
    width: 90%;
    margin: 0 auto;
    margin-top: -50px;
  }
}

@media (max-width: 640px) {
  .member_contents_image1 {
    background-position: 25% 0%;
  }
  .member_contents_image2 {
    background-position: 80% 0%;
  }
  .member_contents_image3 {
    background-position: 90% 0%;
  }
  .member_contents_list1, .member_contents_list2, .member_contents_list3 {
    padding: 1rem;
  }
}

.member_contents {
  width: 100%;
  padding: 0 4rem;
}

.member_contents h2 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 1rem 0 2rem 0;
  color: #11a4bf;
}

.member_contents p {
  line-height: 1.3rem;
  margin-bottom: 1rem;
}

.member_contents p strong {
  background: linear-gradient(transparent 40%, #ffff66 40%);
}

@media (max-width: 640px) {
  .member_contents {
    padding: 0 2rem;
  }
}

.hero_top_about {
  background-image: url(img/hero/10.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 320px;
}

.hero_top_about h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  letter-spacing: 0.2rem;
  color: #fff;
  text-align: center;
  line-height: 3.5rem;
  font-family: 'Caveat Brush', cursive;
}

.hero_top_about h1 span {
  font-size: 1.2rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
}

.about h3 {
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.8rem;
  font-family: Georgia,游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
  letter-spacing: 0.1rem;
}

.about dl {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 4rem;
}

.about dt {
  float: left;
  clear: left;
  width: 15%;
  line-height: 1.8rem;
  vertical-align: middle;
  padding: 0.5rem 0;
  color: #11a4bf;
}

.about dd {
  line-height: 1.8rem;
  border-bottom: 1px solid #11a4bf;
  padding: 0.5rem 0;
  padding-left: 15%;
}

.about .br {
  display: none;
}

.about h4 {
  border-left: 10px solid #11a4bf;
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
}

.about ul {
  display: flex;
  flex-wrap: wrap;
}

.about li {
  width: 25%;
  margin-right: 12%;
  margin-bottom: 2rem;
  font-size: 12px;
}

.about li:nth-child(3) {
  margin-right: 0;
}

.about li:nth-child(6) {
  margin-right: 0;
}

.about li figure {
  width: 100%;
  border-radius: 1000px;
  margin-bottom: 1rem;
  background: #11a4bf;
}

.about li figure a {
  transition: .3s all;
}

.about li figure a:hover {
  opacity: 0.5;
}

.about img {
  width: 100%;
  opacity: 1;
}

.about_photobook {
  display: flex;
  justify-content: space-between;
}

.photobook img, .photobook2 img {
  width: 100%;
}

.photobook, .photobook2 {
  width: 40%;
}

@media (max-width: 768px) {
  .about .br {
    display: block;
  }
  .about li {
    width: 45%;
    margin-right: 5%;
    margin-bottom: 2rem;
    font-size: 12px;
  }
  .about li:nth-child(3) {
    margin-right: 5%;
  }
  .about li:nth-child(6) {
    margin-right: 5%;
  }
  .about li:nth-child(2) {
    margin-right: 0%;
  }
  .about li:nth-child(4) {
    margin-right: 0%;
  }
  .about li:nth-child(6) {
    margin-right: 0;
  }
}

@media (max-width: 640px) {
  .hero_top_about {
    height: 10rem;
  }
  .hero_top_about h1 span {
    line-height: 1.6rem;
    font-size: 1rem;
  }
  .about dt {
    float: none;
    padding: 0.2rem 0;
    color: #11a4bf;
    border-bottom: 1px dotted #11a4bf;
    width: 100%;
  }
  .about dd {
    padding: 0.2rem 0;
    border: none;
    margin-bottom: 1rem;
  }
  .about_photobook {
    display: block;
  }
  .photobook, .photobook2 {
    width: 100%;
  }
  .photobook img, .photobook2 img {
    width: 100%;
  }
  .photobook {
    margin-bottom: 2rem;
  }
}

.hero_top_comunity {
  background-image: url(img/hero/05.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 320px;
}

.hero_top_comunity h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  letter-spacing: 0.2rem;
  color: #fff;
  text-align: center;
  line-height: 3.5rem;
  font-family: 'Caveat Brush', cursive;
}

.hero_top_comunity h1 span {
  font-size: 1.2rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
}

@media (max-width: 640px) {
  .hero_top_comunity {
    height: 10rem;
  }
}

.comunity_btn ul {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.comunity_btn li a {
  display: block;
  text-decoration: none;
}

.comunity_btn li a:hover {
  opacity: 0.6;
}

.comunity_btn li a img {
  border: 1px solid #11a4bf;
  width: 100%;
}

.comunity_btn li {
  width: 45%;
}

@media (max-width: 640px) {
  .comunity_btn ul {
    display: block;
  }
  .comunity_btn ul li {
    width: 100%;
    margin-bottom: 1rem;
  }
}

/*   pasha展ページ   */
.slider-area1 {
  max-width: 400px;
  margin: 0 auto;
}

.slider-area2 {
  width: 600px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  div.member_contents {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .slider-area1 {
    max-width: 100%;
  }
  .slider-area2 {
    width: 100%;
  }
}

.koten_img {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.koten_img li {
  width: 25%;
  text-align: center;
  margin-bottom: 2rem;
}

.koten_img img {
  width: 150px;
  box-shadow: 1px 1px 3px #999;
}

@media (max-width: 1080px) {
  .koten_img li img {
    width: 100px;
  }
}

@media (max-width: 640px) {
  .koten_img li {
    width: 50%;
  }
  .koten_img li img {
    width: 90%;
  }
}

.modal-content {
  width: 500px;
  margin: 0;
  padding: 10px;
  background: #fff;
  position: fixed;
  display: none;
  z-index: 9999999;
  text-align: center;
}

.modal-content p a {
  color: #fff;
  text-decoration: none;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 4rem;
  vertical-align: middle;
  color: #11a4bf;
}

.modal-content p a:hover {
  opacity: 0.5;
  color: #11a4bf;
}

.modal-size img {
  width: 100%;
}

@media (max-width: 640px) {
  .modal-content {
    width: 80%;
  }
  .modal-size img {
    width: 100%;
  }
}

#modal-overlay {
  z-index: 99999;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background-color: rgba(0, 0, 0, 0.75);
}

.button-link {
  color: #00f;
  text-decoration: underline;
}

.button-link:hover {
  cursor: pointer;
  color: #f00;
}

.slider-area2 p {
  text-align: center;
  color: #11a4bf;
  margin-top: 2rem;
}

/*-------------pasha塾---------------*/
.pasha_contents h3 {
  padding: 0.5rem;
  background: #11a4bf;
  border-radius: 100px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
}

.pasha_contents h4 {
  color: #11a4bf;
  margin-bottom: 1rem;
  text-align: center;
  line-height: 1.3rem;
}

.pasha_contents ul {
  margin-bottom: 1rem;
}

.pasha_contents li {
  line-height: 1.5rem;
}

.pasha_contents_img img, .pasha_contents_img2 img {
  width: 100%;
}

.pasha_contents_img {
  width: 45%;
  margin-right: 0;
  margin-left: auto;
  margin-bottom: -240px;
}

.pasha_contents_img2 {
  width: 35%;
  margin-top: 80px;
  margin-bottom: 2rem;
}

.pasha_contents1 {
  width: 65%;
  background: #fff;
  box-shadow: 0 0 5px #999;
  position: relative;
  padding: 2rem;
}

.pasha_contents2 {
  width: 75%;
  margin-right: 0;
  margin-left: auto;
  background: #fff;
  box-shadow: 0 0 5px #999;
  position: relative;
  padding: 2rem;
  margin-top: -350px;
}

@media (max-width: 1200px) {
  .pasha_contents_img {
    width: 100%;
    margin-bottom: 0px;
  }
  .pasha_contents_img2 {
    width: 100%;
    margin-top: 0px;
  }
  .pasha_contents1 {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 3rem;
    margin-top: -50px;
  }
  .pasha_contents2 {
    width: 90%;
    margin: 0 auto;
    margin-top: -50px;
  }
}

@media (max-width: 640px) {
  .pasha_contents1 {
    padding: 1rem;
  }
  .pasha_contents2 {
    padding: 1rem;
  }
}

.hero_top_news {
  background-image: url(img/hero/09.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 320px;
}

.hero_top_news h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  letter-spacing: 0.2rem;
  color: #fff;
  text-align: center;
  line-height: 3.5rem;
  font-family: 'Caveat Brush', cursive;
}

.hero_top_news h1 span {
  font-size: 1.2rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
}

@media (max-width: 640px) {
  .hero_top_news {
    height: 10rem;
  }
  .hero_top_news h1 span {
    font-size: 1rem;
  }
}

.contact_contents {
  width: 100%;
  padding: 0 4rem;
}

@media (max-width: 640px) {
  .contact_contents {
    padding: 0 2rem;
  }
}

.hero_top_contact {
  background-image: url(img/hero/08.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 320px;
}

.hero_top_contact h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  letter-spacing: 0.2rem;
  color: #fff;
  text-align: center;
  line-height: 3.5rem;
  font-family: 'Caveat Brush', cursive;
}

.hero_top_contact h1 span {
  font-size: 1.2rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
}

@media (max-width: 640px) {
  .hero_top_contact {
    height: 10rem;
  }
  .hero_top_contact h1 span {
    font-size: 1rem;
  }
}

.contact_contents p {
  letter-spacing: 0.05rem;
  line-height: 1.3rem;
  text-align: center;
}

.contact_text {
  margin: 2rem 0;
}

table {
  border-collapse: collapse;
  max-width: 80%;
  margin: 0 auto;
}

form td {
  border: 1px solid #11a4bf;
  padding: 1rem;
  line-height: 1.4rem;
}

form th {
  border: 1px solid #11a4bf;
  padding: 1rem;
  width: 25%;
  vertical-align: middle;
  line-height: 1.3rem;
}

input[type="text"], input[type="email"], textarea {
  border: 1px solid #fff;
  background: #eee;
  line-height: 1.3rem;
  width: 100%;
}

input[type="submit"] {
  border: 1px solid #11a4bf;
  color: #11a4bf;
  background: #fff;
  border-radius: 100px;
  width: 100px;
  margin: 2rem 0;
  padding: 0.5rem;
}

input[type="submit"]:hover {
  color: #fff;
  background: #11a4bf;
}

@media (max-width: 960px) {
  table {
    max-width: 100%;
  }
}

@media (max-width: 640px) {
  form th {
    width: 35%;
  }
}

.footer {
  padding: 2rem;
  text-align: center;
}

.footer img {
  width: 83px;
  margin-bottom: 2rem;
}

.footer_nav_list {
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.footer_nav_list li a {
  padding: 1rem;
  display: block;
  text-decoration: none;
  color: #333;
}

.footer_nav_list li a:after {
  content: "|";
  padding-left: 2rem;
}

.footer_nav_list li:first-child a:before {
  content: "|";
  padding-right: 2rem;
}

@media (max-width: 960px) {
  .footer_nav_list li a:after {
    content: "";
    padding-left: 0;
  }
  .footer_nav_list li:first-child a:before {
    content: "";
    padding-right: 0;
  }
}

@media (max-width: 640px) {
  .footer_nav_list {
    display: block;
    line-height: 1.2rem;
  }
  .footer_nav_list li a {
    padding: 0.4rem;
  }
}
