.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  display: none;
  background-color:rgba(255,255,255,0.8);
  border-bottom:solid 1px silver;
}
/*
.header.scroll{
  background-color:rgba(255,255,255,0.8);
  border-bottom:solid 1px silver;
  transition-duration: 0.2s;
}*/

.header_contents {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 1rem 2rem;
}
.header_left h1 img {
  width: 70%;
}
/*PC用g-nav*/
.pc_nav_list {
  display: flex;
  justify-content: space-around;
}
.pc_nav_list {
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
}

.pc_nav_list li a {
    padding: 1rem;
    color: #212121;
    text-decoration: none;
    vertical-align: middle;
    &:hover {
      color: $color-main;
    }
}
@media (max-width: 960px) {
  .pc_nav_list {
    font-size: 1.2rem;
    letter-spacing: 0;
  }
}
.pc_nav_list li {
    line-height: 3rem;
}
@media (max-width: 768px) {
  .header_contents .pc {
    display: none;
  }
}

#pageTop {

  position: fixed;
  bottom: 20px;
  right: 20px;
}
#pageTop a {
  display: block;
  z-index: 999;
  padding: 8px 8px;
  border-radius: 30px;
  width: 35px;
  height: 35px;
  background-color: $color-main;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
}
#pageTop a:hover {
  text-decoration: none;
  opacity: 0.7;
}
