/*
  Theme Name: Pasha
  Theme URI: http://example.com/
  Author: maico
  Author URI: http://example.com/
  Description: Description
  Version: 1.0.0
  License:
  License URI:
  Text Domain: Pasha-wordpress
*/


@import "reset";
@import "common";
@import "header";
@import "nav";
@import "footer";
@import "top";
@import "single";
@import "sidebar";
@import "archive";
@import "concept";
@import 'archive_movie';
@import 'member';
@import 'about';
@import 'comunity';
@import 'news';
@import 'contact';
@import 'footer';
