.sidebar h2 {
    padding: 1rem;
    font-size: 1.1rem;
    background: #eee;
    margin-right: 2rem;
    margin-bottom: 1rem;
}
.sidebar li a {
  color: #333;
  display: block;
  line-height: 1.5rem;
  &:hover {
    opacity: 0.8;
  }
}
.blog_sidebar li :before {
  content: '▶︎';
  color: #ccc;
  font-size: 13px;
  display: inline-block;
  margin-right: 0.25rem;
}
.sidebar li {
  list-style: none;
}
@media (max-width: 768px){
  .sidebar h2 {
    margin-right: 0;
  }
}
