.menu-trigger,
.menu-trigger span{
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  cursor :pointer;
}
.menu-trigger{
  position: relative;
  width: 50px;
  height: 50px;
  z-index: 2;
}
.menu-trigger span{
  position: absolute;
  background: #212121;
  width: 30px;
  height: 2px;
  left: 0;
  right: 0;
  margin: auto;
}
.menu-trigger span:nth-of-type(1){
  top: 15px;
}
.menu-trigger span:nth-of-type(2){
  top: 0;
  bottom: 0;
}
.menu-trigger span:nth-of-type(3){
  bottom: 15px;
}
.menu-trigger.active span:nth-of-type(1){
  -webkit-transform: translateY(9px) rotate(-45deg);
	transform: translateY(9px) rotate(-45deg);
  background: #fff;
}
.menu-trigger.active span:nth-of-type(2){
  opacity: 0;
}
.menu-trigger.active span:nth-of-type(3){
  -webkit-transform: translateY(-9px) rotate(45deg);
	transform: translateY(-9px) rotate(45deg);
    background: #fff;
}
.g-nav{
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
  width: 100%;
  height: 100vh;
}
.g-nav .toggle-list{
  padding-top: 5rem;
}
.g-nav .toggle-list .toggle-tem{
  border-bottom: 1px solid #fff;
}
.g-nav .toggle-list .toggle-item a{
  display: block;
  text-align: center;
  text-decoration: none;
  color: #fff;
  padding: 0.5rem 0;
}
.toggle-item a {
    font-family: 'Caveat Brush', cursive;
    font-size: 1.3rem;
    line-height: 1.8rem;
}
.toggle-item a span {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
    display: block;
    font-size: 0.8rem;
}
