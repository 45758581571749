div.member_contents {
  width: 80%;
  padding: 2rem 4rem;
  margin: 0 auto;
  h2 {
    font-weight: normal;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    vertical-align: top;
    letter-spacing: 0.1rem;
    border-bottom: 1px solid $color-main;
    line-height: 2rem;
    span {
      font-size: 0.9rem;
      margin-top: 0.5rem;
      display: block;
      font-weight: bold;
    }
  }
  p {
    line-height: 1.4rem;
    margin-bottom: 1rem;
  }
}
.hero_top_member {
  background-image: url(img/hero/07.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;;
  height: 320px;
}
.hero_top_member h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  letter-spacing: 0.2rem;
  color: #fff;
  text-align: center;
  line-height: 3.5rem;
  font-family: 'Caveat Brush', cursive;
  span {
    font-size: 1.2rem;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  }
}
@media (max-width: 640px) {
  .hero_top_member {
    height: 10rem;
  }
  .hero_top_member h1 span {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
.member_list ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.member_list li {
    max-width: 30%;
    text-align: center;
}
.member_list li img {
  width: 100%;
}
.member_list a {
    text-decoration: none;
    color: $color-main;
    font-size: 1rem;
    filter: grayscale(100%);
    &:hover {
          filter: grayscale(0%);
    }
}

.member_list h3 {
    margin-bottom: 1rem;
    margin-top: 2rem;
    line-height: 1.5rem;
    span {
      display: block;
      font-size: 13px;
      font-weight: bold;
    }
}
@media (max-width: 768px) {

}
@media (max-width: 640px) {
  .member_list ul {
      display: block;
  }
  .member_list li {
      max-width: 100%;
  }
  .member_list a {
      filter: grayscale(0%);
  }
}
@media (max-width: 640px) {
  .member_contents_layout {
      width: 100%;
  }
  div.member_contents {
    padding: 0 2rem;
  }
}
#list2, #list3 {
  padding-top: 10rem;
  margin-top: -8rem;
}
.member_contents_image1 {
  background: url(img/sunny_prof.jpg);
  background-size: cover;
  height: 520px;
  background-position: top center ;
  width: 85%;
}
.member_contents_image2 {
  background: url(img/master_prof.jpg);
  background-size: cover;
  height: 400px;
  background-position: top center;
  width: 85%;
  margin-left: auto;
  margin-right: 0;
}
.member_contents_image3 {
  background: url(img/maicoo_prof.jpg);
  background-size: cover;
  height: 400px;
  background-position: top right;
  width: 85%;
  margin-left: auto;
  margin-right: 0;
}
.member_contents_list1 {
  width: 60%;
  padding:2rem;
  margin-left: auto;
  margin-right: 0;
  margin-top: -450px;
  background: $color-main;
  box-shadow: 0 0 5px #999;
  color: #fff;
}
.member_contents_list2 {
  width: 55%;
  padding:2rem;
  margin-top: -350px;
  background: $color-main;
  box-shadow: 0 0 5px #999;
  color: #fff;
}
.member_contents_list3 {
  width: 45%;
  padding:2rem;
  margin-top: -350px;
  background: $color-main;
  box-shadow: 0 0 5px #999;
  color: #fff;
}

@media (max-width: 1200px) {
  .member_contents_list3 {
    width: 50%;
  }
}
@media (max-width: 1080px) {
  .member_contents_image1,.member_contents_image2,.member_contents_image3 {
    height: 300px;
    width: 100%;
  }
  .member_contents_image1 {
      background-position: top left ;
  }
  .member_contents_image2 {
      background-position: top right ;
  }
  .member_contents_image3 {
      background-position: top right ;
  }
  .member_contents_list1,.member_contents_list2,.member_contents_list3 {
    width: 90%;
    margin: 0 auto;
    margin-top: -50px;
  }
}
@media (max-width: 640px) {
  .member_contents_image1 {
      background-position: 25% 0% ;
  }
  .member_contents_image2 {
      background-position: 80% 0% ;
  }
  .member_contents_image3 {
      background-position: 90% 0% ;
  }
  .member_contents_list1,.member_contents_list2,.member_contents_list3 {
    padding: 1rem;
  }
}
